<template>
	<button
		class="c-icon-button button"
		:class="{ '--raised': raised }"
		role="button"
		v-bind="$attrs"
		v-on="$listeners"
	>
		<c-icon
			class="c-icon-button__icon"
			:src="icon"
			:size="size"
		/>
	</button>
</template>

<script>
import CIcon from '@components/c-icon';

export default {
	name: 'c-icon-button',

	components: {
		CIcon,
	},

	props: {
		icon: { type: null },
		raised: { type: Boolean },
		size: {
			type: String,
			default: 'l',
		},
	},
};
</script>

<style lang="scss" scoped>
.c-icon-button {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	cursor: pointer;
	color: inherit;
	display: flex;
	position: relative;
	width: 40px;
	height: 40px;
	align-items: center;
	justify-content: center;
	border-radius: var(--border-radius-s);
	white-space: nowrap;
	line-height: 1;
	letter-spacing: 0;
}

.c-icon-button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

.c-icon-button:active {
	outline: none;
}

.c-icon-button:disabled {
	cursor: not-allowed;
	color: RGB(var(--color-text-disabled));
	background: RGB(var(--color-accent-disabled));
}

.c-icon-button__icon {
	color: currentColor;
	font-size: inherit;
}

.c-icon-button.--raised {
	background-color: RGB(var(--c-icon-button-bg, var(--color-surface-light)));
	transition: box-shadow 100ms ease, transform 100ms ease;
	box-shadow: 0px 4px 12px -6px RGB(var(--color-surface-dark));
	will-change: transform;
}

.c-icon-button.--raised:active {
	box-shadow: 0px 4px 12px -8px RGB(var(--color-surface-dark));
	transform: translateY(1px);
}
</style>
