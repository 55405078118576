import {
	USER_INVALID_CRED,
	USER_NOT_FOUND,
	USER_WILL_BE_TEMP_BLOCKED,
	USER_WAS_TEMP_BLOCKED,
	USER_WILL_BE_PERMANENTLY_BLOCKED,
	REMEMBER_TOKEN_INVALID,
} from '@modules/service/constants';

const SET_LOGGED_IN = 'SET_LOGGED_IN';

export default {
	namespaced: true,

	state() {
		return {
			isLoggedIn: false,
		};
	},

	mutations: {
		[SET_LOGGED_IN](state, value) {
			state.isLoggedIn = value;
		},
	},

	actions: {
		async login(
			{ commit, dispatch, rootState },
			{ username, password, rememberToken, isMyDevice }
		) {
			await dispatch('secure/createSession', null, { root: true });
			dispatch('contracts/reset', null, { root: true });
			dispatch('loading/start', null, { root: true });

			const url = '/login';
			const method = 'POST';
			const payload = {
				password,
				channel: 'WEB',
				companyId: rootState.app.companyId,
				deviceId: rootState.device.id,
				isMyDevice,
			};

			if (rememberToken && !username) {
				Object.assign(payload, { rememberToken });
			} else {
				Object.assign(payload, { documentId: username });
			}

			return new Promise((resolve, reject) => {
				dispatch(
					'service/request',
					{
						service: {
							request: {
								url,
								method,
							},
						},
						payload,
					},
					{ root: true }
				)
					.then(async ({ data }) => {
						if (data?.requirePwdChange) {
							const MPasswordChange = await import(
								/* webpackChunkName: "chunk-m-password-change" */ '@modals/m-cbnk-password-change.vue'
							);
							const pwdChangeResponse = await dispatch(
								'modal/open',
								{ component: MPasswordChange },
								{ root: true }
							);

							if (!pwdChangeResponse) {
								throw pwdChangeResponse;
							}
						}

						return dispatch('getContracts', {
							data: { username: data?.username },
						}).then(() => {
							commit(SET_LOGGED_IN, true);

							return resolve(data);
						});
					})
					.catch((error) => {
						dispatch('loading/end', null, { root: true });

						const reasons = {
							BAD_CREDENTIALS: 1 << 0,
							BAD_PASSWORD: 1 << 1,
							BAD_USER: 1 << 2,
						};

						const reason = { ...reasons };
						const { data = {} } = error?.response ?? {};

						reason.status = reasons.BAD_PASSWORD;

						if (data.errorCode === 'CHANGE_USER') {
							reason.status = reasons.BAD_USER;
						}

						if (data.errorCode === REMEMBER_TOKEN_INVALID) {
							reason.status = reasons.BAD_USER | reasons.BAD_CREDENTIALS;
						}

						if (
							data.errorCode === USER_INVALID_CRED ||
							data.errorCode === USER_NOT_FOUND ||
							data.errorCode === USER_WILL_BE_TEMP_BLOCKED ||
							data.errorCode === USER_WAS_TEMP_BLOCKED ||
							data.errorCode === USER_WILL_BE_PERMANENTLY_BLOCKED
						) {
							reason.status |= reasons.BAD_CREDENTIALS;
						}

						commit(SET_LOGGED_IN, false);

						reject(reason);
					});
			});
		},

		loginAnonymous({ dispatch, rootState }) {
			const url = '/login';
			const method = 'POST';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: {
						companyId: rootState.app.companyId,
						deviceId: rootState.device.id,
						channel: 'WEB',
					},
				},
				{ root: true }
			);
		},

		async getContracts(
			{ commit, dispatch, rootState },
			{ data, source, origin }
		) {
			const response = await dispatch('contracts/get', null, { root: true });
			const { contracts = [] } = response;
			const [contract] = contracts;
			const userUUID = rootState.secure?.uuid;
			const userId = data?.userId;

			if (!contract) {
				return Promise.reject();
			}

			if (contract && source && origin) {
				source.postMessage(
					{
						name: 'show-frame',
						userUUID,
						userId,
					},
					origin
				);
			}

			commit(SET_LOGGED_IN, true);

			return dispatch('contracts/set', contract, { root: true });
		},

		async logout({ commit, dispatch, rootState }) {
			const { isEmbedded } = rootState.app;

			if (isEmbedded) {
				const userUUID = rootState.secure.uuid;

				if (window.parent && userUUID) {
					window.parent.postMessage(
						{
							name: 'close-session',
							userUUID,
						},
						'*'
					);
				}
			}

			await dispatch('modal/closeAll', null, { root: true });
			await dispatch('user/clearCache', null, { root: true });
			await dispatch('secure/removeSession', null, { root: true });

			commit(SET_LOGGED_IN, false);

			dispatch('loading/end', null, { root: true });
		},

		async activeLogout({ dispatch, rootState }) {
			const { isEmbedded } = rootState.app;
			const component = await import(
				/* webpackChunkName: "m-logout" */ '@modals/m-logout'
			);
			const isConfirm = await dispatch('modal/open', component, {
				root: true,
			});

			/* istanbul ignore else */
			if (isConfirm) {
				if (!isEmbedded) {
					await dispatch('session/deleteSession', null, { root: true });
				}

				/* istanbul ignore next */
				if (
					navigator.credentials &&
					navigator.credentials.preventSilentAccess
				) {
					navigator.credentials.preventSilentAccess();
				}

				await dispatch('logout');
			}
		},

		async passiveLogout({ dispatch }) {
			await dispatch('logout');

			const component = await import(
				/* webpackChunkName: "m-expired-session" */ '@modals/m-expired-session'
			);

			await dispatch('modal/open', component, { root: true });
		},

		refresh({ dispatch }) {
			const url = '/keep-alive';
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			)
				.then(() => {})
				.catch(() => {});
		},

		async createUserSession(
			{ dispatch, rootState },
			{ data, source, origin }
		) {
			await dispatch('secure/createSession', null, { root: true });
			await dispatch(
				'session/setUserSession',
				{ userName: data?.username },
				{ root: true }
			);

			const url = '/associate-uuids';
			const method = 'POST';
			const { agentUUID, userId } = data;
			const userUUID = rootState.secure.uuid;

			return dispatch(
				'service/request',
				{
					service: {
						headers: {
							'Content-Type': 'application/json',
							'uuid': agentUUID,
							'childuuid': userUUID,
						},
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			)
				.then(() => {
					source.postMessage(
						{
							name: 'session-is-ready',
							userUUID,
							userId,
						},
						origin
					);
				})
				.catch(() => {
					source.postMessage(
						{
							name: 'error',
							userUUID,
							userId,
							text: 'DASHBOARD.SOMETHING_WRONG',
						},
						origin
					);
				});
		},

		authorizeAccess({ dispatch, rootState }, { data, source, origin }) {
			dispatch('loading/start', null, { root: true });

			const url = '/assisted-channels/impersonations';
			const method = 'POST';
			const { userId } = data;
			const userUUID = rootState.secure.uuid;

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: { userId },
				},
				{ root: true }
			)
				.then(() =>
					dispatch('getContracts', {
						data,
						source,
						origin,
					})
				)
				.catch(() =>
					source.postMessage(
						{
							name: 'error',
							userUUID,
							userId,
							text: 'DASHBOARD.SOMETHING_WRONG',
						},
						origin
					)
				);
		},
	},
};
