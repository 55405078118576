import { render, staticRenderFns } from "./w-modal.vue?vue&type=template&id=a6efba00&scoped=true"
import script from "./w-modal.vue?vue&type=script&lang=js"
export * from "./w-modal.vue?vue&type=script&lang=js"
import style0 from "./w-modal.vue?vue&type=style&index=0&id=a6efba00&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6efba00",
  null
  
)

component.options.__file = "w-modal.vue"
export default component.exports