import authn from './authn/m-authn';
import bugsnag from '@modules/bugsnag/m-bugsnag';
import contracts from './contracts/m-contracts';
import communications from './communications/m-communications';
import device from './device/m-device';
import loading from '@modules/loading/m-loading';
import marketplace from '@modules/marketplace/m-marketplace';
import modal from '@modules/modal/m-modal';
import otp from './otp/m-otp';
import products from '@modules/products/m-products';
import secure from '@modules/secure/m-secure';
import service from '@modules/service/m-service';
import session from './session/m-session';
import signatures from '@modules/signatures/m-signatures';
import user from '@modules/user/m-user';
import app from './app/m-app';

export default {
	app,
	authn,
	bugsnag,
	contracts,
	communications,
	device,
	loading,
	marketplace,
	modal: modal(),
	otp,
	products,
	secure,
	service,
	session,
	signatures,
	user,
};
