import SessionCache from '@plugins/cache/index';

const SET_IS_BLOCKED = 'SET_IS_BLOCKED';
const SET_IS_RESTRICTED = 'SET_IS_RESTRICTED';
const SET_IS_COMMUNICATION_SHOWN = 'SET_IS_COMMUNICATION_SHOWN';
const restrictedIds = ['GDPR_BLOQ'];

const cache = new SessionCache('communications');

export default {
	namespaced: true,

	state() {
		return {
			isBlocked: false,
			isRestricted: false,
			isCommunicationShown: false,
		};
	},

	mutations: {
		[SET_IS_BLOCKED](state, value) {
			state.isBlocked = value;
		},

		[SET_IS_RESTRICTED](state, value) {
			state.isRestricted = value;
		},

		[SET_IS_COMMUNICATION_SHOWN](state, isCommunicationShown) {
			state.isCommunicationShown = isCommunicationShown;
		},
	},

	actions: {
		/**
		 * @param {Object} Store
		 * @param {String} type
		 * @returns []
		 */
		async getAnnouncements(
			{ commit, dispatch, state, rootState },
			{ type, refresh }
		) {
			const MAX_SLIDES = 3;
			const isPrelogin = type === 'prelogin';
			const isPostlogin = type === 'postlogin';
			const prefix = isPrelogin ? type : 'communications';
			const url = `/${prefix}/communications`;
			const method = 'GET';

			if (isPostlogin && state.isCommunicationShown) {
				return Promise.resolve({ data: [] });
			}

			const spaceIds = {
				postlogin: 2,
				banner: 3,
			};

			const queryParams = isPrelogin
				? {
						language: rootState.session.lang,
						company: rootState.app.companyId,
				  }
				: { spaceId: spaceIds[type] };

			const cacheKey = `communications/${type}`;

			if (refresh) {
				SessionCache.clear(cacheKey);
			}

			if (cache.has(cacheKey)) {
				return cache.get(cacheKey);
			}

			/* istanbul ignore next */
			if (isPrelogin) {
				await dispatch('secure/createSession', null, { root: true });
			}

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams,
				},
				{ root: true }
			)
				.then(({ data: { data } }) => {
					const validRedirects = [
						'/blocking-communication/gdpr',
						'/blocking-communication/insurance',
					];

					// Filter blocking campaigns for specific ctaRedirects
					const filteredData = data.filter(
						(item) =>
							!(
								item.blocking &&
								!validRedirects.includes(item.ctaRedirect)
							)
					);

					const sortedData = filteredData
						.sort((a, b) => a.type?.priority - b.type?.priority)
						.slice(0, MAX_SLIDES);
					const isBlocked = sortedData.some((item) => item.blocking);
					const isRestricted = sortedData.some((item) =>
						restrictedIds.includes(item.id)
					);

					commit(SET_IS_BLOCKED, isBlocked);
					commit(SET_IS_RESTRICTED, !isBlocked && isRestricted);

					if (type !== 'postlogin') {
						cache.set(cacheKey, sortedData);
					}

					return sortedData;
				})
				.catch(() => {});
		},

		changeAnnouncementData({ dispatch }, { id, data }) {
			const url = `/communications/communications/${id}`;
			const method = 'PATCH';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: { ...data },
				},
				{ root: true }
			);
		},

		setAnnauncementCtaPressed({ dispatch }, { id, ctaPressed }) {
			return dispatch('changeAnnouncementData', {
				id,
				data: { ctaPressed },
			});
		},

		setAnnouncementFeedback({ dispatch }, { id, feedback }) {
			return dispatch('changeAnnouncementData', {
				id,
				data: { feedback },
			});
		},

		setAnnouncementImpression({ dispatch }, id) {
			return dispatch('changeAnnouncementData', {
				id,
				data: { impressions: 1 },
			});
		},

		postLoginDisplayed({ commit }, value) {
			commit(SET_IS_COMMUNICATION_SHOWN, value);
		},
	},
};
