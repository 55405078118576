import { privateCache } from './cache';

class SessionCache {
	constructor(cacheKey) {
		this.id = cacheKey;
	}

	get store() {
		if (!privateCache.has(this.id)) {
			privateCache.set(this.id, new Map());
		}

		return privateCache.get(this.id);
	}

	has(key) {
		return this.store.has(key);
	}

	get(key) {
		return this.store.get(key);
	}

	set(key, value) {
		this.store.set(key, value);
	}

	clear(key) {
		if (this.store.has(key)) {
			this.store.clear(key);
		} else {
			this.store.clear();
		}
	}

	static clear(key) {
		if (privateCache.has(key)) {
			privateCache.delete(key);
		} else {
			privateCache.clear();
		}
	}
}

export default SessionCache;
