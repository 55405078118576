<template>
	<div id="vuesoma">
		<router-view />
		<c-fullscreen-spinner
			v-if="status"
			data-testid="spinner"
		/>
		<w-modal />
	</div>
</template>

<script>
import { throttle } from 'lodash';
import { mapState } from 'vuex';
import { importLocale } from '@locales/setup';
import CFullscreenSpinner from '@components/c-fullscreen-spinner.vue';
import WModal from '@widgets/w-modal.vue';
import MConfirm from '@modals/m-confirm';

const ONE_SECOND = 1000;
const TEN_SECONDS = 10000;
const MAX_MINUTES_FOR_SESSION_EXPIRATION = 5;
const MAX_MINUTES_FOR_SESSION_EXPIRATION_ALERT = 4.5;
let ONCE = true;

const isPrd = window.VUE_APP_CONFIG?.env === 'prd';

export default {
	name: 'app',

	components: {
		CFullscreenSpinner,
		WModal,
	},

	data() {
		return {
			expiredSessionTimeout: 0,
			alertSessionTimeout: 0,
			expiredSessionMaxMinutes: MAX_MINUTES_FOR_SESSION_EXPIRATION,
			alertSessionMaxMinutes: MAX_MINUTES_FOR_SESSION_EXPIRATION_ALERT,
		};
	},

	computed: {
		...mapState('app', ['isEmbedded']),
		...mapState('authn', ['isLoggedIn']),
		...mapState('session', ['theme', 'lang', 'userId']),
		...mapState('loading', ['status']),
		...mapState('service', ['lastRequestTimestamp']),
		...mapState('secure', ['uuid']),
	},

	watch: {
		/* istanbul ignore next */
		isLoggedIn(value) {
			if (!value) {
				clearInterval(this.expiredSessionTimeout);
				clearInterval(this.alertSessionTimeout);

				if (this.$route.name !== 'login') {
					this.$router.push({ name: 'login' });
				}
			} else {
				this.expiredSessionTimeout = setInterval(
					this.checkForSessionExpiration,
					ONE_SECOND
				);
				this.alertSessionTimeout = setInterval(
					this.alertForSessionAboutToExpire,
					ONE_SECOND
				);
			}
		},

		theme: {
			immediate: true,
			handler(value) {
				document.documentElement.dataset.theme = value;
			},
		},

		lang(value) {
			importLocale(value);
		},

		/* istanbul ignore next */
		uuid(next, prev) {
			/* istanbul ignore else */
			if (prev && !next && window.parent) {
				window.parent.postMessage(
					{
						name: 'error',
						userId: this.userId,
						sessionUUID: prev,
						error: 'session-expired',
					},
					'*'
				);
			}
		},
	},

	methods: {
		getMinutesFromLastRequest(lastRequestTimestamp) {
			return (
				(new Date(Date.now()).getTime() - lastRequestTimestamp) / 1000 / 60
			);
		},

		async alertForSessionAboutToExpire() {
			const {
				$store: { dispatch },
				lastRequestTimestamp,
				alertSessionMaxMinutes,
			} = this;

			/* istanbul ignore else */
			if (
				this.getMinutesFromLastRequest(lastRequestTimestamp) >
				alertSessionMaxMinutes
			) {
				const props = {
					title: this.$t('INFO.SESSION_ABOUT_EXPIRE.TITLE'),
					text: this.$t('INFO.SESSION_ABOUT_EXPIRE.DESC'),
					acceptText: this.$t('ACTIONS.CONTINUE_SESSION'),
					cancelText: this.$t('ACTIONS.CLOSE_SESSION'),
				};

				clearInterval(this.alertSessionTimeout);

				const confirmation = await dispatch('modal/open', {
					component: MConfirm,
					props,
				});

				// Refresh session if user clicks accept or closes the modal
				if (confirmation || confirmation === null) {
					this.alertSessionTimeout = setInterval(
						this.alertForSessionAboutToExpire,
						ONE_SECOND
					);

					return dispatch('authn/refresh');
				}

				dispatch('authn/logout');
			}
		},

		checkForSessionExpiration() {
			const { lastRequestTimestamp, expiredSessionMaxMinutes } = this;
			const minutesFromLastRequest =
				(new Date(Date.now()).getTime() - lastRequestTimestamp) / 1000 / 60;

			/* istanbul ignore else */
			if (minutesFromLastRequest > expiredSessionMaxMinutes) {
				clearInterval(this.expiredSessionTimeout);

				return this.$store.dispatch('authn/passiveLogout');
			}
		},

		handleRefresh() {
			if (this.isLoggedIn) {
				this.$store.dispatch('authn/refresh');
			}
		},
	},

	created() {
		/* istanbul ignore next */
		this.$router.afterEach((to, from) => {
			this.$store.dispatch('bugsnag/log', {
				type: 'navigation',
				title: 'Router push',
				from: from.path,
				to: to.path,
			});
		});

		window.dispatchEvent(new Event('app-about-to'));

		/* istanbul ignore next */
		if (!isPrd && !this.isEmbedded) {
			const { log } = console;

			[
				'native-hide-loading',
				'native-copy-text',
				'native-open-external-link',
				'native-save-file',
				'native-share-file',
				'native-exit-app',
				'native-open-whatsapp',
				'native-open-email',
				'native-open-telephone',
				'native-set-theme',
				'native-get-wallet-status',
				'native-get-wallet-state',
				'native-get-wallet-data',
				'native-add-card-to-wallet',
				'native-start-card-provisioning',
				'splashscreen-unload',
			].forEach((eventName) => {
				window.addEventListener(
					eventName,
					(event) => {
						log({
							type: event?.type,
							detail: { ...event?.detail },
						});
					},
					false
				);
			});

			window.addEventListener(
				'message',
				(event) => {
					if (event?.data?.name) {
						log({
							name: event.data.name,
							data: event?.data,
						});
					}
				},
				false
			);
		}

		this.handleThrottleRefresh = throttle(this.handleRefresh, TEN_SECONDS);
		window.addEventListener('click', this.handleThrottleRefresh, true);
		window.addEventListener('scroll', this.handleThrottleRefresh, true);
		window.addEventListener('keydown', this.handleThrottleRefresh, true);
	},

	updated() {
		this.$nextTick(() => {
			// When skyline is embedded sometimes the frame is mounted
			// but it is not visible so the splash transition can't be listened
			// to fix it we remove the splash screen after the first update
			if (ONCE && this.isEmbedded && document.getElementById('splash')) {
				document.getElementById('splash')?.remove();
				ONCE = false;
			}
		});
	},

	mounted() {
		// We try to guarantee that all child components have been mounted
		// and the entire view has been rendered
		this.$nextTick(() => {
			window.dispatchEvent(new Event('app-is-mounted'));
		});
	},
};
</script>

<style lang="scss">
@import '~@design/index.scss';

#vuesoma {
	display: flex;
	height: 100%;
}

[inert] {
	overflow: hidden;
}

.v__internal-a11y-hide {
	position: absolute;
	clip: rect(1px, 1px, 1px, 1px);
	background: 0 0;
	color: transparent;
	width: 1px;
	height: 1px;
	overflow: hidden;
}
:not([tabindex='-1']):focus:focus-visible::before {
	content: '';
	display: block;
	position: absolute;
	border-radius: var(--focus-ring-radius, inherit);
	top: calc(-1 * var(--focus-ring-padding, 0px) - 1px);
	bottom: calc(-1 * var(--focus-ring-padding, 0px) - 1px);
	left: calc(-1 * var(--focus-ring-padding, 0px) - 1px);
	right: calc(-1 * var(--focus-ring-padding, 0px) - 1px);
	border: 1px solid RGB(var(--focus-ring-color, var(--color-secondary)));
	box-shadow: 0 0 0 1px RGB(var(--focus-ring-color, var(--color-secondary)));
}
</style>
