import MOtp from '@modals/m-cbnk-otp.vue';

export default {
	namespaced: true,

	actions: {
		handle({ dispatch }, { props }) {
			return dispatch(
				'modal/open',
				{
					component: MOtp,
					props,
				},
				{ root: true }
			);
		},

		send({ dispatch }, { processId, otpValue }) {
			const url = `/validation/${processId}`;
			const method = 'PATCH';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: { otpValue: otpValue.toString() },
				},
				{ root: true }
			);
		},

		sendEmailCode({ dispatch }, { processId, otpValue }) {
			const url = `/additionalValidation/${processId}`;
			const method = 'PATCH';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: { otpValue: otpValue.toString() },
				},
				{ root: true }
			);
		},

		requestCode({ dispatch }, processId) {
			const url = `/validation/${processId}`;
			const method = 'PATCH';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: { otpValue: 'resend' },
				},
				{ root: true }
			);
		},
	},
};
