/* eslint-disable object-shorthand */

const iconName = 'eye-slash';
const width = 26;
const height = 26;
const svgPathData =
	'M.982 3.004c.17-.018.342.03.476.134l24.218 18.308a.62.62 0 0 1 .239.421.61.61 0 0 1-.139.461l-.405.49c-.107.13-.264.213-.435.23s-.342-.03-.476-.134L.243 4.606a.62.62 0 0 1-.239-.421.61.61 0 0 1 .139-.461l.405-.49c.107-.13.264-.213.435-.23zm2.598 6.51l1.528 1.155c-.7.707-1.298 1.5-1.784 2.357 1.978 3.486 5.64 5.64 9.636 5.64.806 0 1.59-.1 2.358-.278l1.88 1.42c-1.333.46-2.75.737-4.237.737-4.978 0-9.326-2.804-11.522-6.948a1.23 1.23 0 0 1 0-1.143c.57-1.07 1.29-2.06 2.142-2.94zm4.86 3.675l5.46 4.127c-.31.063-.624.096-.94.096-2.45 0-4.43-1.876-4.52-4.223zm4.52-7.682c4.978 0 9.326 2.804 11.525 6.948a1.23 1.23 0 0 1 0 1.143c-.57 1.07-1.29 2.06-2.142 2.942l-1.528-1.155a11.21 11.21 0 0 0 1.782-2.358c-1.978-3.486-5.64-5.64-9.636-5.64-.805 0-1.59.1-2.358.278l-1.88-1.42c1.333-.463 2.75-.737 4.237-.737zm0 3.133c2.45 0 4.43 1.876 4.52 4.224l-5.46-4.127c.31-.064.624-.096.94-.097z';

exports.definition = {
	iconName: iconName,
	icon: [width, height, svgPathData],
};

exports.eyeSlash = exports.definition;
