const SET_USER_SESSION = 'SET_USER_SESSION';
const SET_REMEMBER_TOKEN = 'SET_REMEMBER_TOKEN';
const SET_THEME = 'SET_THEME';
const REMOVE_REMEMBER_TOKEN = 'REMOVE_REMEMBER_TOKEN';
const REMOVE_USER_SESSION = 'REMOVE_USER_SESSION';
const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
const SKIP_NEWS = 'SKIP_NEWS';

const USER_SESSION_KEY = 'USER_SESSION';
const THEME_SESSION_KEY = 'themeSession';
const SKIP_NEWS_KEY = 'SKIP_NEWS';

export default {
	namespaced: true,

	state() {
		const userSession =
			JSON.parse(localStorage.getItem(USER_SESSION_KEY)) || {};
		const themeSession =
			JSON.parse(localStorage.getItem(THEME_SESSION_KEY)) || {};
		const isNewsOmitted = localStorage.getItem(SKIP_NEWS_KEY) || '';
		const { userName = '', lastLogin = '', rememberToken = '' } = userSession;
		const { theme = 'light' } = themeSession;

		return {
			lang: 'es',
			theme,
			userName,
			lastLogin,
			rememberToken,
			isNewsOmitted,
		};
	},

	mutations: {
		[SET_USER_SESSION](state, { userName, lastLogin }) {
			state.userName = userName;
			state.lastLogin = lastLogin;
		},

		[SET_REMEMBER_TOKEN](state, data) {
			state.rememberToken = data;
		},

		[REMOVE_USER_SESSION](state) {
			state.userName = '';
			state.lastLogin = '';
		},

		[REMOVE_REMEMBER_TOKEN](state) {
			state.rememberToken = null;
		},

		[CHANGE_LANGUAGE](state, value) {
			state.lang = value;
		},

		[SET_THEME](state, value) {
			state.theme = value;
		},

		[SKIP_NEWS](state, value) {
			state.isNewsOmitted = value;
		},
	},

	actions: {
		setUserSession({ commit }, { userName, lastLogin }) {
			commit(SET_USER_SESSION, {
				userName,
				lastLogin,
			});
		},

		rememberUserSession({ commit }, { userName, lastLogin, rememberToken }) {
			commit(SET_USER_SESSION, {
				userName,
				lastLogin,
			});
			commit(SET_REMEMBER_TOKEN, rememberToken);

			localStorage.setItem(
				USER_SESSION_KEY,
				JSON.stringify({
					userName,
					lastLogin,
					rememberToken,
				})
			);
		},

		removeUserSession({ commit }) {
			commit(REMOVE_USER_SESSION);
			commit(REMOVE_REMEMBER_TOKEN);
			sessionStorage.removeItem('SECURE_SESSION');
		},

		forgetUserSession() {
			localStorage.removeItem(USER_SESSION_KEY);
		},

		setAppTheme({ commit }, theme) {
			commit(SET_THEME, theme);
			localStorage.setItem(THEME_SESSION_KEY, JSON.stringify({ theme }));
		},

		changeLanguage({ commit, dispatch }, lang) {
			commit(CHANGE_LANGUAGE, lang);

			// To notify backend the language change so that if any documents are downloaded these will be translated.
			const method = 'PATCH';
			const url = '/current/user/language';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: { language: lang },
				},
				{ root: true }
			);
		},

		skipNews({ commit }, version) {
			commit(SKIP_NEWS, version);
			localStorage.setItem(SKIP_NEWS_KEY, version);
		},

		deleteSession({ dispatch }) {
			const method = 'DELETE';
			const url = '/session';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			);
		},

		deleteCache({ dispatch }) {
			const method = 'DELETE';
			const url = '/cache';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			);
		},
	},
};
