const SET_IS_EMBEDDED = 'SET_IS_EMBEDDED';

export default {
	namespaced: true,

	state() {
		return {
			isEmbedded: false,
			isHybrid: false,
			isHybridSky: false,
			isIOS: false,
			companyId: 'FA',
			onboardingUrl: '',
			telephone: '+34 913 086 428 / +34 900 107 901',
			email: 'comunicaciones-medseguros@cbnk.es',
			cookiesPolicyLink:
				'https://www.cbnk.es/aviso-legal#politica-de-cookies',
			bizumSafetyAdvice: '',
			measurementId: '',
			legalIdentity: '',
			legalDisclaimerLink: 'http://www.cbnk.es/aviso-legal',
			liveagent: {},
		};
	},

	mutations: {
		[SET_IS_EMBEDDED](state, value) {
			state.isEmbedded = value;
		},
	},

	actions: {
		setIsEmbedded({ commit }) {
			commit(SET_IS_EMBEDDED, true);
		},
	},
};
