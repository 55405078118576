export default [
	{
		path: '*',
		redirect: { name: 'login' },
	},
	{
		path: '/',
		name: 'login',
		components: {
			default: () =>
				import(
					/* webpackChunkName: "v-login", webpackPrefetch: true */ '@fam/views/v-login.vue'
				),
		},
	},
	{
		path: '/blocking-communication/',
		name: 'blocking-communication',
		props: { default: true },
		components: {
			default: () =>
				import(
					/* webpackChunkName: "v-blocking-communication", webpackPrefetch: true */ '@fam/views/v-blocking-communication'
				),
		},
		children: [
			{
				path: 'gdpr',
				name: 'gdpr',
				props: { default: true },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "v-gdpr-management", webpackPrefetch: true */ '@fam/views/v-gdpr-management'
						),
				},
			},
			{
				path: 'insurance',
				name: 'insurance',
				components: {
					default: () =>
						import(
							/* webpackChunkName: "v-acceptances", webpackPrefetch: true */ '@fam/views/v-acceptances'
						),
				},
			},
		],
	},
	{
		path: '/main/',
		name: 'main',
		components: {
			default: () =>
				import(
					/* webpackChunkName: "v-main", webpackPrefetch: true */ '@fam/views/v-main.vue'
				),
		},
		children: [
			{
				path: 'products',
				name: 'products',
				components: {
					default: () =>
						import(
							/* webpackChunkName: "v-products", webpackPrefetch: true */ '@fam/views/v-products.vue'
						),
				},
			},
			{
				path: 'signatures',
				name: 'signatures',
				components: {
					default: () =>
						import(
							/* webpackChunkName: "v-signatures", webpackPrefetch: true */ '@fam/views/v-signatures.vue'
						),
				},
			},
			{
				path: 'signatures/detail',
				name: 'signature-detail',
				props: { default: true },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "v-signature-detail", webpackPrefetch: true */ '@fam/views/v-signature-detail.vue'
						),
				},
			},
			{
				path: 'marketplace',
				name: 'marketplace',
				components: {
					default: () =>
						import(
							/* webpackChunkName: "v-marketplace", webpackPrefetch: true */ '@fam/views/v-marketplace.vue'
						),
				},
			},
			{
				path: 'marketplace/category/:categoryId/:categoryName?',
				name: 'marketplace-category',
				props: { default: true },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "v-marketplace-category" */ '@fam/views/v-marketplace-category'
						),
				},
			},
			{
				path: 'marketplace/detail/:productId?',
				name: 'marketplace-detail',
				props: { default: true },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "v-marketplace-detail", webpackPrefetch: true */ '@fam/views/v-marketplace-detail.vue'
						),
				},
			},
			{
				path: 'products/detail/:productId?',
				name: 'products-detail',
				props: { default: true },
				components: {
					default: () =>
						import(
							/* webpackChunkName: "v-products-detail", webpackPrefetch: true */ '@fam/views/v-products-detail.vue'
						),
				},
			},
			{
				path: 'personal-area',
				name: 'personal-area',
				components: {
					default: () =>
						import(
							/* webpackChunkName: "v-personal-area", webpackPrefetch: true */ '@fam/views/v-personal-area.vue'
						),
				},
			},
			{
				path: 'personal-area/personal-data',
				name: 'personal-data',
				components: {
					default: () =>
						import(
							/* webpackChunkName: "v-personal-data", webpackPrefetch: true */ '@fam/views/v-personal-data.vue'
						),
				},
			},
			{
				path: 'personal-area/security-and-privacy',
				name: 'security-and-privacy',
				components: {
					default: () =>
						import(
							/* webpackChunkName: "v-security-and-privacy", webpackPrefetch: true */ '@fam/views/v-security-and-privacy.vue'
						),
				},
			},
			{
				path: 'personal-area/security-and-privacy/gdpr-management',
				name: 'gdpr-management',
				components: {
					default: () =>
						import(
							/* webpackChunkName: "v-gdpr-management", webpackPrefetch: true */ '@fam/views/v-gdpr-management.vue'
						),
				},
			},
			{
				path: 'personal-area/security-and-privacy/password-management',
				name: 'password-management',
				components: {
					default: () =>
						import(
							/* webpackChunkName: "v-password-management", webpackPrefetch: true */ '@fam/views/v-password-management.vue'
						),
				},
			},
		],
	},
];
