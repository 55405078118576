export default {
	install(Vue, { store }) {
		Vue.mixin({
			beforeCreate() {
				if (this.$options.modules) {
					Object.entries(this.$options.modules).forEach(async ([key]) => {
						const modules = (await import('@local-modules')).default;
						const module = modules[key];

						// if the module is not already registered
						if (!store.hasModule(key) && module) {
							store.registerModule(key, module);
						}
					});
				}
			},
		});
	},
};
