const SET_CONNECTED_CONTRACT = 'SET_CONNECTED_CONTRACT';

export default {
	namespaced: true,

	state() {
		return {
			connectedContract: null,
		};
	},
	mutations: {
		[SET_CONNECTED_CONTRACT](state, value) {
			state.connectedContract = value;
		},
	},

	actions: {
		reset({ commit }) {
			commit(SET_CONNECTED_CONTRACT, null);
		},

		get({ dispatch }) {
			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url: '/contracts',
							method: 'GET',
						},
					},
				},
				{ root: true }
			)
				.then(({ data }) => data)
				.catch(/* istanbul ignore next */ (err) => err);
		},

		set({ commit, dispatch, state }, contract) {
			const { connectedContract } = state;

			if (connectedContract?.id === contract?.id) {
				return;
			}

			const { id } = contract;
			const url = `/contracts/${id}`;
			const method = 'PATCH';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			)
				.then(({ data }) => {
					commit(SET_CONNECTED_CONTRACT, data);

					return data;
				})
				.catch(/* istanbul ignore next */ (err) => err);
		},
	},
};
