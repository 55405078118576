<template>
	<l-modal class="m-otp-expired">
		<template v-slot:icon>
			<c-icon
				class="m-otp-expired__icon"
				src="@icons/modalExclamation"
			/>
		</template>

		<template v-slot:header>
			{{ $t('OTP_EXPIRED_TITLE') }}
		</template>

		<article class="m-otp-expired__content">
			<p class="m-otp-expired__desc text-m-book">
				{{ $t('OTP_EXPIRED_DESC') }}
			</p>
		</article>

		<template v-slot:buttons>
			<c-button
				class="m-otp-expired__button"
				raised
				data-testid="resend"
				@click="onResend"
			>
				{{ $t('OTP_EXPIRED_RESEND') }}
			</c-button>
		</template>
	</l-modal>
</template>

<script>
import LModal from '@layouts/l-modal';
import CButton from '@components/c-button';
import CIcon from '@components/c-icon';

export default {
	name: 'm-otp-expired',

	components: {
		LModal,
		CButton,
		CIcon,
	},

	data() {
		return {
			value: '',
		};
	},

	methods: {
		onResend() {
			this.value = 'resend';
			this.$emit('close');
		},
	},
};
</script>

<style lang="scss" scoped>
.m-otp-expired__content {
	color: RGB(var(--color-text-primary));
	display: flex;
	position: relative;
	width: 100%;
	height: 100%;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	padding: var(--spacing-s);
}

.m-otp-expired__desc {
	margin-bottom: var(--spacing-xl);
}
</style>
