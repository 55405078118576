import { render, staticRenderFns } from "./m-otp-error.vue?vue&type=template&id=2e79893a&scoped=true"
import script from "./m-otp-error.vue?vue&type=script&lang=js"
export * from "./m-otp-error.vue?vue&type=script&lang=js"
import style0 from "./m-otp-error.vue?vue&type=style&index=0&id=2e79893a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e79893a",
  null
  
)

component.options.__file = "m-otp-error.vue"
export default component.exports