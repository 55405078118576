const UPDATE_STATUS = 'UPDATE_STATUS';
const UPDATE_TYPE = 'UPDATE_TYPE';

export default {
	namespaced: true,

	state() {
		return {
			type: '',
			status: null,
		};
	},

	mutations: {
		[UPDATE_STATUS](state, value) {
			state.status = value;
		},

		[UPDATE_TYPE](state, value) {
			state.type = value;
		},
	},

	actions: {
		start({ commit }, type) {
			commit(UPDATE_TYPE, type);
			commit(UPDATE_STATUS, true);
		},

		end({ commit }) {
			commit(UPDATE_TYPE, '');
			commit(UPDATE_STATUS, false);
		},
	},
};
