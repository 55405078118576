import Vue from 'vue';
import VueI18n from 'vue-i18n';
import i18nExtended from '@plugins/i18nExtended';
import numberFormats from './numberFormats';
import dateTimeFormats from './dateTimeFormats';

Vue.use(VueI18n);
Vue.use(i18nExtended);

export const fallbackLocale = 'es';

// Hack to separate thousands from the third character instead of the fourth.
// Example:
// $n(1234, 'EUR') in Spanish is 1234 €
// $n(1234, 'EUR') in Catalan is 1.234 €
// So, numbers in Catalan will be preferred in Spanish.

const patch = function patch(fn) {
	return function patchedFn(value, locale, key, options) {
		if (key) {
			return fn.call(this, value, locale, key, options);
		}
		return fn.call(
			this,
			value,
			locale === 'es' ? 'ca' : locale,
			key,
			options
		);
	};
};

VueI18n.prototype._n = patch(VueI18n.prototype._n);
VueI18n.prototype._ntp = patch(VueI18n.prototype._ntp);

const i18n = new VueI18n({
	fallbackLocale,
	preserveDirectiveContent: true,
	numberFormats,
	dateTimeFormats,
});

const loadedLanguages = new Set();

const setI18nLanguage = (lang) => {
	i18n.locale = lang;
	document.querySelector('html').setAttribute('lang', lang);

	return lang;
};

const getLocales = async (lang) => {
	// load global lang
	const locales = await import(
		/* webpackChunkName: 'locale-[request]' */
		`@locales/${lang}`
	);

	try {
		// try loading project lang
		const localLocales = await import(
			/* webpackChunkName: 'local-locale-[request]' */
			`@local-locales/${lang}`
		);

		Object.assign(locales.default, localLocales.default);
	} catch {
		//
	}

	return locales;
};

export const importLocale = async (lang, vertical) => {
	if (vertical) {
		const verticalType = {
			1: 'eng',
			2: 'sal',
		}[vertical];

		const verticalLocales = await import(
			/* webpackChunkName: 'vertical-locale-[request]' */
			`@cbnk/vertical/${verticalType}/locales/${lang}.json`
		);

		return getLocales(lang)
			.then((locales = {}) => {
				const mergedLocales = {
					...locales,
					...verticalLocales.default,
				};

				i18n.setLocaleMessage(lang, mergedLocales);

				loadedLanguages.add(lang);

				return setI18nLanguage(lang);
			})
			.catch(() => {});
	}

	// If the same language
	if (i18n.locale === lang) {
		return Promise.resolve(setI18nLanguage(lang));
	}

	// If the language was already loaded
	if (loadedLanguages.has(lang)) {
		return Promise.resolve(setI18nLanguage(lang));
	}

	return getLocales(lang)
		.then((msgs) => {
			i18n.setLocaleMessage(lang, msgs.default);
			loadedLanguages.add(lang);

			return setI18nLanguage(lang);
		})
		.catch(() => {});
};

export default i18n;
