<template>
	<l-modal>
		<template v-slot:icon>
			<c-icon
				class="icon"
				src="@icons/modalExclamation"
			/>
		</template>

		<template v-slot:header>
			{{ $t('OTP_ERROR_TITLE') }}
		</template>

		<article>
			<p>{{ signUpBizum ? $t('OTP_ERROR_BIZUM') : $t('OTP_ERROR_DESC') }}</p>
			<c-contact-support-info-fam v-if="isFam" />
			<c-contact-support-info v-else />
		</article>

		<template v-slot:buttons>
			<c-button
				data-testid="accept"
				raised
				@click="$emit('close')"
			>
				{{ signUpBizum ? $t('ACTIONS.RESTART') : $t('ACTIONS.ACCEPT') }}
			</c-button>
		</template>
	</l-modal>
</template>

<script>
import LModal from '@layouts/l-modal';
import CButton from '@components/c-button';
import CIcon from '@components/c-icon';
import CContactSupportInfo from '@components/c-contact-support-info';
import CContactSupportInfoFam from '@fam/components/c-contact-support-info';
import { mapState } from 'vuex';

export default {
	name: 'm-otp-error',

	components: {
		LModal,
		CButton,
		CIcon,
		CContactSupportInfo,
		CContactSupportInfoFam,
	},
	props: {
		signUpBizum: { type: Boolean },
	},

	computed: {
		...mapState('app', ['companyId']),

		isFam() {
			return this.companyId === 'FA';
		},
	},
};
</script>

<style lang="scss" scoped>
article {
	display: flex;
	flex-direction: column;
	min-height: 120px;
	margin: 0 auto;
}

article *:not(:last-child) {
	margin-bottom: 20px;
}

@media ($s-screen) {
	article *:not(:last-child) {
		margin-bottom: 30px;
	}
}

.icon {
	color: RGB(var(--color-accent-error));
}
</style>
