<template>
	<l-modal class="m-otp-invalid">
		<template v-slot:icon>
			<c-icon
				class="m-otp-invalid__icon"
				src="@icons/modalExclamation"
			/>
		</template>

		<template v-slot:header>
			{{ $t('OTP_INVALID_TITLE') }}
		</template>

		<article class="m-otp-invalid__content">
			<p class="m-otp-invalid__desc">
				{{ $t('OTP_INVALID_DESC') }}
			</p>
			<c-contact-support-info-fam
				:isVertical="!isLargeScreen"
				v-if="isFam"
			/>
			<c-contact-support-info
				:isVertical="!isLargeScreen"
				v-else
			/>
		</article>

		<template v-slot:buttons>
			<c-button
				class="m-otp-invalid__button"
				raised
				data-testid="accept"
				@click="$emit('close')"
			>
				{{ $t('ACTIONS.ACCEPT') }}
			</c-button>
		</template>
	</l-modal>
</template>

<script>
import mq from '@utils/matchMedia';
import { screenL } from '@screens';
import LModal from '@layouts/l-modal';
import CButton from '@components/c-button';
import CIcon from '@components/c-icon';
import CContactSupportInfo from '@components/c-contact-support-info';
import CContactSupportInfoFam from '@fam/components/c-contact-support-info';
import { mapState } from 'vuex';

export default {
	name: 'm-otp-invalid',

	components: {
		LModal,
		CButton,
		CIcon,
		CContactSupportInfo,
		CContactSupportInfoFam,
	},

	computed: {
		isLargeScreen: mq(screenL),

		...mapState('app', ['companyId']),

		isFam() {
			return this.companyId === 'FA';
		},
	},
};
</script>

<style lang="scss" scoped>
.m-otp-invalid__content {
	color: RGB(var(--color-text-primary));
	display: flex;
	position: relative;
	width: 100%;
	height: 100%;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	padding: var(--spacing-s);
}

.m-otp-invalid__desc {
	margin-bottom: var(--spacing-xl);
}
</style>
