export default {
	'account': [
		'checking-account',
		'account',
		'junior-account',
		'support-account',
	],

	'hefame-account': ['hefame-account'],

	'currency-account': ['currency-account'],

	'debit-card': ['debit-card', 'business-debit-card'],

	'credit-card': ['credit-card', 'business-credit-card'],

	'deposit': ['term-deposit', 'demand-deposit'],

	'currency-deposit': ['currency-deposit'],

	'pension-plan': ['pension-plan'],

	'investment-pension-plan': ['investment-pension-plan'],

	'caminos-equities': ['caminos-equities'],

	'equities': ['securities-account'],

	'fund': ['advised-fund', 'commercialized-fund', 'delegated-fund'],

	'loan': ['loan-var', 'loan-fixed', 'loan-mix'],

	'endorsement': ['endorsement', 'endorsement-line'],

	'credit': ['guarantee-policy-var', 'guarantee-policy-fixed'],

	'discount-line': ['discount-line'],

	'pending-movements': ['pending-movements'],

	'investment': [
		'investment-account-rto',
		'investment-account-nonoperative',
		'investment-account-advice',
	],

	'managed-portfolio': ['managed-portfolio'],

	'managed-rsi-portfolio': ['managed-rsi-portfolio'],

	'managed-rsi-product': [
		'managed-rsi-account',
		'managed-rsi-currency-account',
	],

	'managed-product': [
		'managed-account',
		'managed-currency-account',
		'managed-debit-card',
		'managed-credit-card',
		'managed-deposit',
		'managed-currency-deposit',
		'managed-fund',
		'managed-equities',
	],

	'subscription': [
		'premium-account',
		'premium-debit-card',
		'premium-credit-card',
		'premium-deposit',
	],

	'insurance': ['cars', 'personal', 'property', 'diverse'],
};
