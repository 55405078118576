<template>
	<l-modal class="m-sign-blocked">
		<template v-slot:header>
			{{ $t('INFO.SIGN_BLOCKED.TITLE') }}
		</template>

		<article class="m-sign-blocked__content">
			<p class="content-desc text-m-book">
				{{ $t('INFO.SIGN_BLOCKED.DESC') }}
			</p>

			<c-contact-support-info :isVertical="!isLargeScreen" />
		</article>

		<template v-slot:buttons>
			<c-button
				data-testid="accept"
				@click="$emit('close')"
			>
				{{ $t('ACTIONS.ACCEPT') }}
			</c-button>
		</template>
	</l-modal>
</template>

<script>
import mq from '@utils/matchMedia';
import { screenL } from '@screens';
import LModal from '@layouts/l-modal';
import CButton from '@components/c-cbnk-button';
import CContactSupportInfo from '@components/c-contact-support-info';

export default {
	name: 'm-cbnk-sign-blocked',

	components: {
		LModal,
		CButton,
		CContactSupportInfo,
	},

	data() {
		return {
			value: null,
		};
	},

	computed: {
		isLargeScreen: mq(screenL),
	},
};
</script>

<style lang="scss" scoped>
.m-sign-blocked__content {
	color: RGB(var(--color-text-primary));
	display: flex;
	position: relative;
	width: 100%;
	height: 100%;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
}

.content-desc {
	margin-bottom: var(--spacing-xl);
}
</style>
