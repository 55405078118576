import Vue from 'vue';
import VueRouter from 'vue-router';
import { importLocale } from '@locales/setup';
import store from '@fam/store';
import routes from './routes';
import serviceWorkerFlow from '@/serviceWorkerFlow';

Vue.use(VueRouter);

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior: (to, from, savedPosition) =>
		savedPosition || {
			x: 0,
			y: 0,
		},
	routes,
});

router.onError((error) => {
	if (error.type === 'error') {
		store.dispatch('service/setOffline', true);
	}
});

router.beforeEach(async (to, from, next) => {
	const publicPages = ['login'];
	const isAuthRequired = !publicPages.includes(to.name);
	const { dispatch, state } = store;
	const { isEmbedded } = state.app;
	const { isOffline } = state.service;
	const { isLoggedIn } = state.authn;
	const { lang } = state.session;
	const isDev = process.env.NODE_ENV === 'development';
	const redirect = {
		name: 'login',
		query: { redirect: to.fullPath },
		replace: true,
	};
	const hasModalInQueue = Boolean(
		store.hasModule('modal') && Object.keys(state.modal.queue).length
	);

	if (lang) {
		await importLocale(lang);
	}

	// We cancel navigation in offline mode or in blocked navigation
	if (isOffline) {
		return;
	}

	// We close any modal if there is a navigation with a modal opened
	if (hasModalInQueue) {
		await dispatch('modal/closeAll');

		return next(false);
	}

	// We request the exit and logout after navigate to login on embedded version
	if (to?.name === 'login' && isEmbedded) {
		if (isLoggedIn) {
			await store.dispatch('authn/logout');
		}

		return;
	}

	if (isAuthRequired && !isLoggedIn) {
		// Autologin: used only on mocked servers
		/* istanbul ignore next */
		if (isDev) {
			const session = sessionStorage.getItem('SECURE_SESSION');

			if (session) {
				const { rememberToken, password } = JSON.parse(session);

				if (rememberToken && password) {
					return store
						.dispatch('authn/login', {
							rememberToken,
							password,
						})
						.then(({ theme = 'light' }) =>
							dispatch('session/setAppTheme', theme)
						)
						.then(({ username: userName, lastLogin }) =>
							store.dispatch('session/setUserSession', {
								userName,
								lastLogin,
							})
						)
						.then(() => next())
						.catch(() => next(redirect));
				}
			}
		}

		return next(redirect);
	}

	next();
});
let once = false;

router.afterEach(() => {
	if (!once) {
		once = true;

		/**
		 * We don't use the "to" param to avoid redundant navigation error
		 * We create a state on the history
		 */
		if (router.currentRoute?.query?.entryPoint) {
			const to = router.resolve(router.currentRoute.query.redirect).route;

			router.push({
				name: to.name,
				params: to.params,
				query: to.query,
			});
		}
		serviceWorkerFlow();
	}
});

export default router;
