<template>
	<c-transition appear>
		<div
			class="c-fullscreen-spinner"
			:class="{ '--is-spinner': type === 'spinner' }"
			v-bind="$attrs"
		>
			<div class="c-fullscreen-spinner__e1"></div>
			<div class="c-fullscreen-spinner__e2"></div>
		</div>
	</c-transition>
</template>

<script>
import CTransition from './c-transition.vue';

export default {
	name: 'c-fullscreen-spinner',

	components: { CTransition },

	props: {
		type: { type: String },
	},
};
</script>

<style lang="scss" scoped>
@import '@local-design/_local-style-loading';

.c-fullscreen-spinner {
	@extend %spinner-bg !optional;
}

.c-fullscreen-spinner.--is-spinner {
	@extend %spinner-bg-transparent !optional;
}

.c-fullscreen-spinner__e1 {
	@extend %spinner-e1 !optional;
}
.c-fullscreen-spinner__e2 {
	@extend %spinner-e2 !optional;
}
</style>
