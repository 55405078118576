import { render, staticRenderFns } from "./m-something-wrong.vue?vue&type=template&id=68dd5528&scoped=true"
import script from "./m-something-wrong.vue?vue&type=script&lang=js"
export * from "./m-something-wrong.vue?vue&type=script&lang=js"
import style0 from "./m-something-wrong.vue?vue&type=style&index=0&id=68dd5528&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68dd5528",
  null
  
)

component.options.__file = "m-something-wrong.vue"
export default component.exports