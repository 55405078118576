<template>
	<p
		class="c-input-message text-m-book"
		:class="classes"
	>
		<span
			v-if="$slots.icon"
			class="c-input-message__icon"
		>
			<slot name="icon" />
		</span>
		<span class="c-input-message__text">
			{{ text }}
		</span>
	</p>
</template>

<script>
export default {
	name: 'c-cbnk-input-message',

	props: {
		text: { type: String },
		type: { type: String },
	},

	computed: {
		classes({ type }) {
			return {
				'c-input-message--has-error': type === 'error',
				'c-input-message--is-success': type === 'success',
			};
		},
	},
};
</script>

<style lang="scss" scoped>
.c-input-message {
	color: RGB(var(--color-text-primary));
	display: inline-flex;
	position: relative;
	width: 100%;
	align-items: flex-start;
	justify-content: flex-end;
	text-align: right;
	overflow: hidden;
}

.c-input-message--has-error {
	color: RGB(var(--color-accent-error));
}

.c-input-message--is-success {
	color: RGB(var(--color-accent-success));
}

.c-input-message__icon {
	display: flex;
	align-items: center;
	margin-right: var(--spacing-s);
}
</style>
