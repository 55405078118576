<template>
	<component
		:is="componentType"
		:href="href"
		class="c-cbnk-link-text text-m-book"
		:class="classes"
		v-bind="$attrs"
		v-on="$listeners"
	>
		<slot />
	</component>
</template>

<script>
export default {
	name: 'c-cbnk-link-text',

	props: {
		isPersistent: { type: Boolean },
		hasUnderline: { type: Boolean },
		href: { type: String },
	},

	computed: {
		classes({ isPersistent, href, hasUnderline }) {
			return {
				'--is-persistent': isPersistent,
				'button': !href,
				'--underline': hasUnderline,
			};
		},

		componentType({ href }) {
			return href ? 'a' : 'button';
		},
	},
};
</script>

<style lang="scss" scoped>
.c-cbnk-link-text {
	cursor: pointer;
	color: RGB(var(--c-cbnk-link-text-color, var(--color-text-white)));
	display: inline-flex;
	position: relative;
	border-bottom: none;
	padding-bottom: calc(var(--spacing-xs));
	text-decoration: none;
}

.c-cbnk-link-text.--underline {
	text-decoration: underline;
}

.c-cbnk-link-text.--underline:hover {
	color: RGB(
		--c-cbnk-link-text-color-hover,
		var(--color-text-secondary-light)
	);
}

.c-cbnk-link-text.--is-persistent {
	border-bottom: 1px solid currentColor;
}

.c-cbnk-link-text:not(.--underline):hover {
	color: RGB(var(--color-text-secondary-light));
}

.c-cbnk-link-text:not(.--is-persistent):not(.--underline):hover {
	border-bottom: 1px solid currentColor;
	padding-bottom: calc(var(--spacing-xs) - 1px);
}
</style>
