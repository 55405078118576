import { render, staticRenderFns } from "./c-contact-support-info.vue?vue&type=template&id=3ed63db8&scoped=true"
import script from "./c-contact-support-info.vue?vue&type=script&lang=js"
export * from "./c-contact-support-info.vue?vue&type=script&lang=js"
import style0 from "./c-contact-support-info.vue?vue&type=style&index=0&id=3ed63db8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ed63db8",
  null
  
)

component.options.__file = "c-contact-support-info.vue"
export default component.exports